/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    OnlinePatientManagementLayout,
    IMenuItem,
    OnlinePatientManagementContext,
    InstitutionsByCodesRoute,
    RouteLoading,
    CrfFormByCodesRoutes,
    PatientSummaryByStudyNumberRoute,
    useIsMobile,
    useAuthenticatedUser,
    //UserType,
    ICrfFormMapping,
    UserType,
    InstitutionsByIdsResolver
} from '@ngt/opms';

import { SaeByPatientStudyNumberRoute } from '@ngt/opms-sae'

import { EConsentPage } from '@ngt/opms-econsent'

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi'

import { RequestState } from '@ngt/request-utilities';

/**
 * Used for font styling.
 */
import { Typography } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/**
 * Used for the patient icon.
 */
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

/**
 * Used for the ecrf icon.
 */
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';

/**
 * Used for the sae icon.
 */
import { faFileMedical as saeIcon } from '@fortawesome/pro-duotone-svg-icons/faFileMedical';

/**
 * Used for the screening log icon.
 */
import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

/**
 * Used for the econsent icon.
 */
import { faFileSignature as eConsentIcon } from '@fortawesome/pro-duotone-svg-icons/faFileSignature';

import { Switch, Redirect, Route } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import Registration from './containers/Registration';

import PatientSummary from './containers/PatientSummary';

import * as Dtos from './api/dtos';
import StatusForm from './containers/form/StatusForm';
import PatientTransfer from './containers/PatientTransfer';
import PatientEventRoute from './components/route/PatientEventRoute';
import SaeSummary from './containers/SaeSummary';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';

const ParticipantForm = React.lazy(() => import('./containers/form/ParticipantForm'));



/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const eConsentSettings = global.ECONSENT_SETTINGS as Dtos.GetEConsentSettingsResponse;

const patientSummaryMapping: Record<string, ICrfFormMapping> = {
    "participant-form": {
        components:
            <InstitutionsByIdsResolver
                resolveBeforeLoad={true}
            >
                <PatientSummary />
            </InstitutionsByIdsResolver>
    }
}

const formMapping: Record<string, ICrfFormMapping> = {
    "participant-form": {
        components:
            <InstitutionsByIdsResolver
                resolveBeforeLoad={true}
            >
                <ParticipantForm />
            </InstitutionsByIdsResolver>
    }
}

const permissions: Permission[] = [
    Permission.OpmsAccess,
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform,
    Permission.ScreeningLogView,
    Permission.ScreeningLogUpdate,
    Permission.EConsentView,
    Permission.EConsentAdminister,
    Permission.EConsentManage
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const App: React.FunctionComponent = () => {
    const [user] = useAuthenticatedUser(); 

    const isPatient = user?.type === UserType.Patient;

    const [[canAccessOpms, canViewSae, , , , canViewScreeningLog, , canViewEConsent, , ], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true);

    const menuItems: IMenuItem[] = React.useMemo(() => {

        if (isPatient) {
            return [];
        }

        let items: IMenuItem[] = [];

        // disable econsent
        if (canViewEConsent && eConsentSettings.eConsentActivated) {
            items.push({
                url: '/econsent',
                label: 'eConsent',
                external: false,
                newTab: false,
                icon: eConsentIcon
            });
        }

        items.push(
            {
                url: '/registration',
                label: 'Participants',
                external: false,
                newTab: false,
                icon: faUser
            }
        );

        if (canViewSae) {
            items.push({
                url: '/sae',
                label: 'SAE Summary',
                external: false,
                newTab: false,
                icon: saeIcon
            });
        }

        //if (canViewScreeningLog) {
        //    items.push({
        //        url: '/screeninglog',
        //        label: 'Screening Log',
        //        external: false,
        //        newTab: false,
        //        icon: screeningLogIcon
        //    });
        //}

        return items;
    }, [isPatient, canViewEConsent, canViewSae, canViewScreeningLog]);

    const canView = (permissionLoadState.state === RequestState.Cancel || 
        permissionLoadState.state === RequestState.Failure || 
        permissionLoadState.state === RequestState.Success) &&
        (canAccessOpms || canViewEConsent || canViewSae || canViewScreeningLog);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const isMobile = useIsMobile();

    if ( permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <OnlinePatientManagementLayout
            menuItems={menuItems}
            footer={
                isMobile ?
                    undefined :
                    <Typography variant="caption">
                        Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2022 ANZ Breast Cancer Trials Group Ltd | ABN 64 051 369 496
                    </Typography>
            }
        >
            {
                canView ? 
                    <React.Suspense fallback={<RouteLoading />}>
                        <Switch>
                            <CrfFormByCodesRoutes
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/add-participant`
                                ]}
                                eventDefinitionCode="randomisation"
                                eventRepeat={1}
                                formDefinitionCode="participant-form"
                                formRepeat={1}
                                createPatient
                                resolveBeforeLoad
                                formMapping={formMapping}
                            />
                            <CrfFormByCodesRoutes
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                                ]}
                                resolveBeforeLoad
                                formMapping={formMapping}
                            />
                            <PatientEventRoute
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/transfer`
                                ]}
                                eventDefinitionCode="randomisation"
                                eventRepeat={1}
                                resolveBeforeLoad
                            >
                                <InstitutionsByIdsResolver
                                    resolveBeforeLoad={true}
                                >
                                    <PatientTransfer />
                                </InstitutionsByIdsResolver>
                            </PatientEventRoute>
                            <CrfFormByCodesRoutes
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`
                                ]}
                                eventDefinitionCode="randomisation"
                                eventRepeat={1}
                                formDefinitionCode="participant-form"
                                formRepeat={1}
                                resolveBeforeLoad
                                formMapping={patientSummaryMapping}
                            />
                            <InstitutionsByCodesRoute
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                    '/registration'
                                ]}
                            >
                                <Registration />
                            </InstitutionsByCodesRoute>
                            <InstitutionsByCodesRoute
                                path={[
                                    `/sae/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                    '/sae'
                                ]}
                            >
                                <SaeSummary />
                            </InstitutionsByCodesRoute>
                            <InstitutionsByCodesRoute
                                path={[
                                    `/econsent/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                    '/econsent'
                                ]}
                            >
                                <EConsentPage />
                            </InstitutionsByCodesRoute>
                            <Redirect path="*" to="/registration" />
                            <Redirect path="*" to="/registration/admin" />
                        </Switch> 
                    </React.Suspense> :
                    <ErrorPage errorType={ErrorTypeEnum.e403} />
            }
        </OnlinePatientManagementLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default App;
