/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { makeStyles, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Tooltip, darken, Paper, Link, lighten, IconButton, ThemeProvider, useTheme } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    PatientSummaryList,
    PatientContext,
    PatientValidationContext,
    PatientBreadcrumbs,
    PatientInformation,
    PatientInformationFn,
    ProgressButton,
    InstitutionContext,
    MasterGroupContext,
    CollaboratingGroupContext,
    RouteLoading,
    ValidationResultType,
    useSnackbar,
    IPatientSummaryActionProps,
    OnlinePatientManagementContext,
    IPatient,
    ALL_INSTITUTIONS_CODE,
    FormByCodesResolver,
    FormContext,
    InstitutionsByIdsResolver,
    InstitutionsContext,
    FormsContext,
    CollapsibleTable,
    FormsByCodesResolver,
    IForm,
    FormDefinitionByCodeResolver,
    EventDefinitionByCodeResolver,
    useFormDefinitionByCode,
    useLookupsByFormDefinitionId,
    LookupsContext,
    FormDefinitionContext,
    CrfFormByCodesResolver
} from '@ngt/opms';

import Alert from '@material-ui/lab/Alert';

import AlertTitle from '@material-ui/lab/AlertTitle';
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';
import { RequestState } from '@ngt/request-utilities';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { usePermissionsByIds } from '@ngt/opms-bctapi';
import { JsonServiceClient } from '@servicestack/client';
import * as classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import { getStatus } from '../hooks/useStatusColumns';
import useStatusColumns from '../hooks/useStatusColumns';
import { Column } from 'material-table';
import usePathologyColumns from '../hooks/usePathologyColumns';
import useSaeColumns from '../hooks/useSaeColumns';
import StatusForm from './form/StatusForm';
import PathologyForm from './form/PathologyForm';
import SeriousAdverseEventForm from './form/SeriousAdverseEventForm';
import DialogFormResolver, { ICrfFormMappingProps } from '../components/resolver/DialogFormResolver';
import { camelCase } from 'lodash-es';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientSummaryParams {
    institutionCode?: string
    patientStudyNumber: string
}

interface IPatientSummaryProps {
}

interface IFormTableProps {
    title: string,
    columns: Column<IForm>[],
    onRowClick: any,
    onAddClick: any,
    onRemoveClick: any,
    showRemoveButton?: (rowData: IForm, forms: IForm[]) => boolean,
    backButton?: React.ReactNode,
    disabledTableText?: string
}

interface IFormDialogProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    form: React.ReactNode,
    loading: boolean,
    width?: "xs" | "sm" | "md" | "lg" | "xl"
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
    },
    tableContainer: {
        padding: theme.spacing(4, 0 ,0),
    }
}));

const usePatientSummaryCardStyles = makeStyles(theme => ({
    patientSummaryCardContainer: {
        position: 'relative',

        '&:hover': {
            '& $updateButton': {
                opacity: '1',
            }
        }
    },
    patientSummaryCardIcon: {
        backgroundColor: darken(theme.palette.primary.main, 0.15),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3, 0),
        fontSize: '8rem',
        transition: "background 1s",

        [theme.breakpoints.down('md')]: {
            fontSize: '6rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '5rem',
        }
    },
    patientSummaryCardPaper: {
        color: theme.palette.common.white,
        display: 'flex',
        overflow: 'hidden',
        transition: "background 1s",
    },
    patientSummaryCardText: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: theme.spacing(0, 3),
        width: '20em',

        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0),
            width: '18em',
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0),
            width: '16em',
            alignItems: 'center',
        }
    },
    patientSummaryCardInfo: {
        display: 'flex',
        flexFlow: 'wrap',
        color: theme.palette.primary.main,

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-around',
        }
    },
    patientSummaryCardLink: {
        textDecoration: 'none !important',
        cursor: 'pointer'
    },
    text: {
        fontSize: '1.5rem',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: '1rem',
        }
    },
    overline: {
        lineHeight: 'inherit'
    },
    updateButtonContainer: {
        display: 'flex',
        justifyContent: 'start',
        //opacity: '0'
    },
    updateButton: {
        opacity: '0',
        transition: 'opacity 1s',
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.OpmsPatientView,
];

const PatientSummaryCard = () => {
    const classes = usePatientSummaryCardStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const history = useHistory();

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, false);

    const { institutionCode } = useParams<IPatientSummaryParams>();

    const { institutions } = React.useContext(InstitutionsContext);

    const { form: untypedForm  } = React.useContext(FormContext);

    const form: Dtos.PatientForm | null = untypedForm as unknown as Dtos.PatientForm;

    React.useEffect(() => {
        //reload the contexts if the institution or the study number was updated in the participant form
        const patientInstitutionCode = institutions?.find(i => i.id === form.currentInstitutionId)?.code;

        if ((institutionCode !== ALL_INSTITUTIONS_CODE && institutionCode !== patientInstitutionCode) || patient?.studyNumber != form.participantNumber) {
            history.push(`/registration/${patientInstitutionCode}/${form.participantNumber}`);
        }

    }, [history, institutionCode, institutions, patient, form]);

    return (
        <div
            className={classes.patientSummaryCardContainer}
        >
            <Paper
                className={classes.patientSummaryCardPaper}
            >
                <Grid
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={2}
                        className={classes.patientSummaryCardIcon}
                    >
                        <FontAwesomeIcon icon={faUser} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        xl={10}
                        className={classes.patientSummaryCardInfo}
                    >
                        <div
                            className={classes.patientSummaryCardText}
                        >
                            <Typography
                                className={classes.text}
                            >
                                {patient?.studyNumber}
                            </Typography>
                            <Typography
                                className={classes.overline}
                                variant="overline"
                            >
                                Participant Number
                            </Typography>
                        </div>
                        <div
                            className={classes.patientSummaryCardText}
                        >
                            <Typography
                                className={classes.text}
                            >
                                {form?.dateRandomised ? DateTime.fromISO(form.dateRandomised).toFormat('dd/MM/yyyy') : null}
                            </Typography>
                            <Typography
                                className={classes.overline}
                                variant="overline"
                            >
                                Date Randomised
                            </Typography>
                        </div>
                        <div
                            className={classes.patientSummaryCardText}
                        >
                            <Typography
                                className={classes.text}
                            >
                                {getStatus(form?.status)}
                            </Typography>
                            <Typography
                                className={classes.overline}
                                variant="overline"
                            >
                                Status
                            </Typography>
                        </div>
                        <div
                            className={classes.patientSummaryCardText}
                        >
                            <Typography
                                className={classes.text}
                            >
                                {institutions?.find(i => i.id == form?.randomisingInstitutionId)?.name}
                            </Typography>
                            <Typography
                                className={classes.overline}
                                variant="overline"
                            >
                                Randomising Institution
                            </Typography>
                        </div>
                        <div
                            className={classes.patientSummaryCardText}
                        >
                            <Typography
                                className={classes.text}
                            >
                                {institutions?.find(i => i.id == form?.currentInstitutionId)?.name}
                            </Typography>
                            <Typography
                                className={classes.overline}
                                variant="overline"
                            >
                                Current Institution
                            </Typography>
                        </div>

                        {
                            !!canUpdatePatient && (
                                <div
                                    className={classes.patientSummaryCardText}
                                    style={{ justifyContent: 'end' }}
                                >
                                    <div className={classes.updateButtonContainer}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
                                            onClick={() => {
                                                history.push(`/registration/${institutionCode}/${patient?.studyNumber}/transfer`);
                                            }}
                                        >
                                            Transfer
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                                            onClick={() => {
                                                history.push(`/registration/${institutionCode}/${patient?.studyNumber}/randomisation/1/participant-form/1`);
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>    
    );
}

export const FormSummaryTable: React.FunctionComponent<IFormTableProps> = ({
    title,
    columns,
    onRowClick,
    onAddClick,
    onRemoveClick,
    showRemoveButton,
    backButton,
    disabledTableText
}) => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, false);

    const { forms: untypedForms, loadState: formsLoadState } = React.useContext(FormsContext);

    const formsLoading = formsLoadState.state === RequestState.None || formsLoadState.state === RequestState.Pending;

    const columnsToUse = React.useMemo(() => {
        const actionColumn: (Column<IForm>)[] = [
            {
                field: 'id',
                title: 'Remove',
                render: rowData => (
                    (showRemoveButton?.(rowData, untypedForms ?? []) ?? true) && <div style={{ fontSize: '1em', display: 'flex', justifyContent: 'center' }}>
                        <IconButton color="primary" size="small" onClick={e => onRemoveClick(e, rowData)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </div>
                ),
                align: 'center',
                sorting: false
            },
        ];

        return !!canUpdatePatient ? [...columns, ...actionColumn] : columns;
    }, [columns, untypedForms, canUpdatePatient, showRemoveButton, onRemoveClick])

    return (
        <>
            <CollapsibleTable
                data={untypedForms ?? []}
                title={title}
                pluralizeTitle={false}
                entityName={"record"}
                loading={formsLoading}
                columns={columnsToUse}
                onRowClick={canUpdatePatient ? onRowClick : undefined}
                disabledTableText={disabledTableText}
            />
            <div
                style={{ display: 'flex', justifyContent: 'end'}}
            >
                {
                    !!canUpdatePatient && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '0.5rem' }}
                            onClick={onAddClick}
                        >
                            Add
                        </Button>    
                    )
                }
                
                {backButton}
            </div>
        </>
    );
}

export const FormDialog: React.FunctionComponent<IFormDialogProps> = ({
    open,
    setOpen,
    form,
    loading,
    width
}) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog" maxWidth={width} fullWidth scroll="body">
            <DialogContent style={{ padding: '0' }}>
                {!loading ? form : <RouteLoading />}
            </DialogContent>
        </Dialog>
    );
}

const StatusFormDialog: React.FunctionComponent<ICrfFormMappingProps> = ({ open, setOpen, mode, loading }) => {

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, false);

    return (
        <FormDialog
            open={open}
            setOpen={setOpen}
            form={
                <StatusForm
                    exitForm={() => setOpen(false)}
                    mode={mode}
                    disable={!canUpdatePatient}
                />
            }
            loading={loading}
            width="sm"
        />  
    );
}

const PathologyFormDialog: React.FunctionComponent<ICrfFormMappingProps> = ({ open, setOpen, mode, loading }) => {

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, false);

    return (
        <FormDialog
            open={open}
            setOpen={setOpen}
            form={
                <PathologyForm
                    exitForm={() => setOpen(false)}
                    mode={mode}
                    disable={!canUpdatePatient}
                />
            }
            loading={loading}
            width="sm"
        />
    );
}

const SeriousAdverseEventFormDialog: React.FunctionComponent<ICrfFormMappingProps> = ({ open, setOpen, mode, loading }) => {

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, false);

    return (
        <FormDialog
            open={open}
            setOpen={setOpen}
            form={
                <SeriousAdverseEventForm
                    exitForm={() => setOpen(false)}
                    mode={mode}
                    disable={!canUpdatePatient}
                />
            }
            loading={loading}
            width="md"
        />
    );
}

const formMapping = {
    "status-form": {
        component: StatusFormDialog
    },
    "pathology-form": {
        component: PathologyFormDialog
    },
    "serious-adverse-event-form": {
        component: SeriousAdverseEventFormDialog
    }
}

const PatientSummary: React.FunctionComponent<IPatientSummaryProps> = () => {

    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();

    React.useEffect(() => {
        if (permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewPatient) {
                history.replace(`/registration`);
            }
        }
    }, [canViewPatient, permissionLoadState, history]);

    const statusColumns = useStatusColumns();

    const pathologyColumns = usePathologyColumns();

    const saeColumns = useSaeColumns(false);

    const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);

    const [pathologyDialogOpen, setPathologyDialogOpen] = React.useState(false);

    const [saeDialogOpen, setSaeDialogOpen] = React.useState(false);

    const [formRepeat, setFormRepeat] = React.useState<number | undefined>(undefined);

    const [formMode, setFormMode] = React.useState<"save" | "remove">("save");

    const onStatusRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        setFormRepeat(rowData?.repeat);
        setStatusDialogOpen(true);
        setFormMode("save");
    }, [setStatusDialogOpen, setFormRepeat, setFormMode]);

    const onStatusAddClick = React.useCallback(() => {
        setFormRepeat(undefined);
        setStatusDialogOpen(true);
        setFormMode("save");
    }, [setStatusDialogOpen, setFormRepeat, setFormMode]);

    const onStatusRemoveClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        event.stopPropagation();
        setFormRepeat(rowData?.repeat);
        setStatusDialogOpen(true);
        setFormMode("remove");
    }, [setStatusDialogOpen, setFormRepeat, setFormMode]);

    const onPathologyRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        setFormRepeat(rowData?.repeat);
        setPathologyDialogOpen(true);
        setFormMode("save");
    }, [setPathologyDialogOpen, setFormRepeat, setFormMode]);

    const onPathologyAddClick = React.useCallback(() => {
        setFormRepeat(undefined);
        setPathologyDialogOpen(true);
        setFormMode("save");
    }, [setPathologyDialogOpen, setFormRepeat, setFormMode]);

    const onPathologyRemoveClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        event.stopPropagation();
        setFormRepeat(rowData?.repeat);
        setPathologyDialogOpen(true);
        setFormMode("remove");
    }, [setStatusDialogOpen, setFormRepeat, setFormMode]);

    const onSaeRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        setFormRepeat(rowData?.repeat);
        setSaeDialogOpen(true);
        setFormMode("save");
    }, [setSaeDialogOpen, setFormRepeat, setFormMode]);

    const onSaeAddClick = React.useCallback(() => {
        setFormRepeat(undefined);
        setSaeDialogOpen(true);
        setFormMode("save");
    }, [setSaeDialogOpen, setFormRepeat, setFormMode]);

    const onSaeRemoveClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        event.stopPropagation();
        setFormRepeat(rowData?.repeat);
        setSaeDialogOpen(true);
        setFormMode("remove");
    }, [setStatusDialogOpen, setFormRepeat, setFormMode]);

    const statusSummaryShowRemoveButton = React.useCallback((rowData: IForm, forms: IForm[]) => {
        return !!forms && forms.length > 0 && (forms[0].id != rowData.id)
    }, []);

    //if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
    //    return (
    //        <RouteLoading />
    //    );
    //}

    return (
        <>
            <PatientBreadcrumbs />
            <div
                className={classes.container}
            >
                <PatientSummaryCard />
                <div
                    className={classes.tableContainer}
                >
                    <FormsByCodesResolver
                        formDefinitionIdentifier={"status-form"}
                        patientStudyNumber={patient?.studyNumber}
                        eventDefinitionCode={"randomisation"}
                        eventRepeat={1}
                        resolveBeforeLoad={true}
                    >
                        <FormSummaryTable
                            title="Status Summary"
                            columns={statusColumns}
                            onRowClick={onStatusRowClick}
                            onAddClick={onStatusAddClick}
                            onRemoveClick={onStatusRemoveClick}
                            showRemoveButton={statusSummaryShowRemoveButton}
                            disabledTableText="There are no status"
                        />
                    </FormsByCodesResolver>
                </div>
                <div
                    className={classes.tableContainer}
                >
                    <FormsByCodesResolver
                        formDefinitionIdentifier={"pathology-form"}
                        patientStudyNumber={patient?.studyNumber}
                        eventDefinitionCode={"randomisation"}
                        eventRepeat={1}
                        resolveBeforeLoad={true}
                    >
                        <FormSummaryTable
                            title="Pathology Collection Summary"
                            columns={pathologyColumns}
                            onRowClick={onPathologyRowClick}
                            onAddClick={onPathologyAddClick}
                            onRemoveClick={onPathologyRemoveClick}
                            disabledTableText="There are no pathology collections"
                        />
                    </FormsByCodesResolver>
                </div>
                <div
                    className={classes.tableContainer}
                >
                    <FormsByCodesResolver
                        formDefinitionIdentifier={"serious-adverse-event-form"}
                        patientStudyNumber={patient?.studyNumber}
                        eventDefinitionCode={"randomisation"}
                        eventRepeat={1}
                        resolveBeforeLoad={true}
                    >
                        <FormSummaryTable
                            title="Serious Adverse Events"
                            columns={saeColumns as Column<IForm>[]}
                            onRowClick={onSaeRowClick}
                            onAddClick={onSaeAddClick}
                            onRemoveClick={onSaeRemoveClick}
                        />
                    </FormsByCodesResolver>
                </div>
            </div>

            <DialogFormResolver
                open={statusDialogOpen}
                setOpen={setStatusDialogOpen}
                mode={formMode}
                institutionCode={institution?.code}
                patientStudyNumber={patient?.studyNumber}
                eventDefinitionCode={"randomisation"}
                eventRepeat={1}
                formDefinitionCode={"status-form"}
                formRepeat={formRepeat}
                formMapping={formMapping}
                resolveBeforeLoad
            />
            <DialogFormResolver
                open={pathologyDialogOpen}
                setOpen={setPathologyDialogOpen}
                mode={formMode}
                institutionCode={institution?.code}
                patientStudyNumber={patient?.studyNumber}
                eventDefinitionCode={"randomisation"}
                eventRepeat={1}
                formDefinitionCode={"pathology-form"}
                formRepeat={formRepeat}
                formMapping={formMapping}
                resolveBeforeLoad
            />
            <DialogFormResolver
                open={saeDialogOpen}
                setOpen={setSaeDialogOpen}
                mode={formMode}
                institutionCode={institution?.code}
                patientStudyNumber={patient?.studyNumber}
                eventDefinitionCode={"randomisation"}
                eventRepeat={1}
                formDefinitionCode={"serious-adverse-event-form"}
                formRepeat={formRepeat}
                formMapping={formMapping}
                resolveBeforeLoad
            />
        </>
    );
}

/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientSummary;
