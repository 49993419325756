/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to get access to router context.
 */
import { useHistory, useParams, Link } from 'react-router-dom';

/*
 * Used to style components
 */
import { makeStyles, Grid, Button, Tooltip } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    TrialContextSelector,
    ALL_MASTER_GROUPS_CODE,
    ALL_COLLABORATING_GROUPS_CODE,
    ALL_COUNTRIES_CODE,
    ALL_INSTITUTIONS_CODE,
    InstitutionsContext,
    PatientsByCodesResolver,
    PatientCollapsibleTable,
    CountriesContext,
    InstitutionBreadcrumbs
} from '@ngt/opms';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * used to get column spec for the pre-registered patient table.
 */
import usePatientColumns from '../hooks/usePatientColumns';

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { usePermissionsByIds } from '@ngt/opms-bctapi';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IRegistrationParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IRegistrationProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    addPatientColumn: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    addPatient: {
        width: '100%'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister
];

const patientStates = [Dtos.PatientStateType.Randomised];

const Registration: React.FunctionComponent<IRegistrationProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const { masterGroupCode, collaboratingGroupCode, countryCode, institutionCode } = useParams<IRegistrationParams>();

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history.push(`/registration/${newInstitutionCode}`);
        }
        else {
            history.push(`/registration`);
        }

        return;
    }, [history]);

    const parsedInstitutionCode = React.useMemo(() => {
        return institutionCode !== ALL_INSTITUTIONS_CODE ? institutionCode : null;
    }, [institutionCode]);

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.find(institution => institution.code === parsedInstitutionCode);
    }, [parsedInstitutionCode, institutions]);

    const [[canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, true);

    const showInstitutions = React.useMemo(() => {
        return !parsedInstitutionCode
    }, [parsedInstitutionCode]);

    const institutionsToUse = React.useMemo(() => {
        return institutions?.filter(i => i.code);
    }, [institutions]);

    const patientColumns = usePatientColumns(institutions, showInstitutions);

    const onRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IPatient | undefined) => {
        history.push(`/registration/${institutionCode ?? ALL_INSTITUTIONS_CODE}/${rowData?.studyNumber}`)
    }, [history, institutionCode]);

    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <TrialContextSelector
                    onChange={onSelect}
                    allowAllMasterGroups={true}
                    allowAllCollaboratingGroups={true}
                    allowAllCountries={true}
                    allowAllInstitutions={true}
                    hideMasterGroups={true}
                    hideCollaboratingGroups={true}
                    hideCountries={true}
                    masterGroupCode={masterGroupCode}
                    collaboratingGroupCode={collaboratingGroupCode}
                    countryCode={countryCode}
                    institutionCode={institutionCode}
                    institutions={institutionsToUse}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.addPatientColumn}
                    >
                        {
                            parsedInstitutionCode && canAdminsterOpms ?
                                institution?.enabled ?
                                    <Button
                                        className={classes.addPatient}
                                        variant="contained"
                                        color="secondary"
                                        component={Link}
                                        to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}
                                    >
                                        Add Participant
                                    </Button> :
                                    <Tooltip
                                        className={classes.addPatient}
                                        title="Institution must be active to add a participant"
                                    >
                                        <div>
                                            <Button
                                                className={classes.addPatient}
                                                disabled
                                                variant="contained"
                                                color="secondary"
                                                component={Link}
                                                to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}
                                            >
                                                Add Participant
                                            </Button>
                                        </div>
                                    </Tooltip> :
                                <Tooltip
                                    className={classes.addPatient}
                                    title={canAdminsterOpms ? 'Select an institution to add a participant' : 'Insufficient permissions to add a participant'}
                                >
                                    <div>
                                        <Button
                                            className={classes.addPatient}
                                            disabled
                                            variant="contained"
                                            color="secondary"
                                            component={Link}
                                            to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}
                                        >
                                            Add Participant
                                        </Button>
                                    </div>
                                </Tooltip>
                        }
                    </Grid>
                </TrialContextSelector>
                <PatientsByCodesResolver
                    masterGroupCode={null}
                    collaboratingGroupCode={null}
                    countryCode={null}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={patientStates}
                >
                    <PatientCollapsibleTable
                        title="Participants"
                        entityName="Participant"
                        columns={patientColumns}
                        onRowClick={onRowClick as any}
                        options={{
                            pageSize: 20,
                            pageSizeOptions: [20]
                        }}
                    />
                </PatientsByCodesResolver>
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Registration;
