/* Options:
Date: 2022-08-18 17:19:26
Version: 6.02
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44387

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse,GetUserPersonnelEmailAddress,UserPersonnelEmailAddressResponse,SendRegistrationEmail,SendRandomisationEmail
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IDelete
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export enum StatusTypeEnum
{
    Waiting = 1,
    Responded = 2,
    NotInterested = 3,
    PatientSigning = 4,
    InvestigatorSigning = 5,
    VerifyingDocument = 6,
    Completed = 7,
    Refused = 8,
}

export class StatusType
{
    public id: StatusTypeEnum;
    public value: string;

    public constructor(init?: Partial<StatusType>) { (Object as any).assign(this, init); }
}

export class Invitation
{
    public id?: number;
    public emailAddress: string;
    public institutionCode: string;
    public investigator: string;
    public guid: string;
    public statusId?: StatusTypeEnum;
    public dateSent?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public status: StatusType;

    public constructor(init?: Partial<Invitation>) { (Object as any).assign(this, init); }
}

export interface IEoiGetSingleById extends IGet
{
    id?: number;
}

export interface IEoiGetCollection extends IGet
{
}

export interface IEoiGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export class Eoi implements IEoi
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.Invitation)")
    public invitationId?: number;

    public firstName: string;
    public lastName: string;
    public confirmed?: boolean;
    public dateSubmitted?: string;
    public statusId?: StatusTypeEnum;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public invitation: Invitation;

    public constructor(init?: Partial<Eoi>) { (Object as any).assign(this, init); }
}

export interface IEoiDelete extends IDelete
{
    id?: number;
}

export interface IPatientConsentGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientConsentGetSingleByGuid extends IGet
{
    guid: string;
}

export interface IPatientConsentGetCollection extends IGet
{
}

export interface IPatientConsentGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export enum ConsentTypeEnum
{
    Remote = 1,
    FaceToFace = 2,
    Standard = 3,
}

export class ConsentPicf
{
    public id?: number;
    public consentId?: number;
    public picfTypeId?: number;
    public picfId?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConsentPicf>) { (Object as any).assign(this, init); }
}

export class ConsentFileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<ConsentFileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class ConsentFileUpload
{
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public exists: boolean;
    public image: boolean;
    public upload: ConsentFileUploadTemporary;

    public constructor(init?: Partial<ConsentFileUpload>) { (Object as any).assign(this, init); }
}

export class ConsentType
{
    public id: ConsentTypeEnum;
    public value: string;

    public constructor(init?: Partial<ConsentType>) { (Object as any).assign(this, init); }
}

export class PatientConsent
{
    public id?: number;
    // @References("typeof(Finer.ServiceLogic.Models.Dos.EConsent.Eoi)")
    public eoiId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.ConsentType)")
    public typeId?: ConsentTypeEnum;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.StatusType)")
    public statusId?: StatusTypeEnum;

    public guid: string;
    public dateStarted?: string;
    public mobileNumber: string;
    public patientSigned?: boolean;
    public datePatientSigned?: string;
    public investigatorSigned?: boolean;
    public dateInvestigatorSigned?: string;
    public documentVerified?: boolean;
    public documentVerifiedBy: string;
    public dateDocumentVerified?: string;
    public dateNotificationSent?: string;
    public contactAboutGeneticRisks?: boolean;
    public alternativeContactName: string;
    public alternativeContactAddress: string;
    public alternativeContactNumber: string;
    public alternativeContactEmail: string;
    public storeTissueAndBloodSamples?: boolean;
    public storeImages?: boolean;
    // @Ignore()
    public picfs: ConsentPicf[];

    // @Ignore()
    public documentUpload: ConsentFileUpload;

    public type: ConsentType;
    public status: StatusType;
    public eoi: Eoi;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientConsent>) { (Object as any).assign(this, init); }
}

export interface IPatientConsentDelete extends IDelete
{
    id?: number;
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IEventDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IEventDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IEventDefinitionGetCollection extends IGet
{
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IPatientStateGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientStateGetCollection extends IGet
{
}

export interface ITreatmentGetSingleById extends IGet
{
    id?: number;
}

export interface ITreatmentGetCollection extends IGet
{
}

export interface IMasterGroupGetSingleById extends IGet
{
    id?: number;
}

export interface IMasterGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface IMasterGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetSingleById extends IGet
{
    id?: number;
}

export interface ICollaboratingGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface ICollaboratingGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICollaboratingGroupGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetSingleById extends IGet
{
    id?: number;
}

export interface ICountryGetSingleByCode extends IGet
{
    code: string;
}

export interface ICountryGetCollection extends IGet
{
}

export interface ICountryGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICountryGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
}

export interface ICountryGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
}

export interface IInstitutionGetSingleById extends IGet
{
    id?: number;
}

export interface IInstitutionGetSingleByCode extends IGet
{
    code: string;
}

export interface IInstitutionGetCollection extends IGet
{
}

export interface IInstitutionGetCollectionByCountryId extends IGet
{
    countryId?: number;
}

export interface IInstitutionGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCountryCode extends IGet
{
    countryCode: string;
}

export interface IInstitutionGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
}

export interface IInstitutionGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
}

export interface IPatientGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientGetSingleByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetValidationById extends IGet
{
    id?: number;
}

export interface IPatientGetValidationByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetCollection extends IGet
{
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryId extends IGet
{
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryCode extends IGet
{
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
    patientStateIds: number[];
}

export class Patient implements IPatient
{
    public id?: number;
    public studyNumber: string;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public patientStateId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.Treatment)")
    public treatmentId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Data.Institution)")
    public institutionId?: number;

    public institutionCode: string;
    // @Ignore()
    public dateRandomised?: string;

    // @Ignore()
    public status?: number;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export interface IEventGetSingleById extends IGet
{
    id?: number;
}

export interface IEventGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetCollection extends IGet
{
}

export interface IEventGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export interface IEventGetCollectionByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
}

export class Event implements IEvent
{
    public id?: number;
    public patientId?: number;
    public eventDefinitionId?: number;
    public repeat?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Event>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
}

export interface IFormGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export interface IFormGetCollectionByIds extends IGet
{
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export interface IFormGetCollectionByCodes extends IGet
{
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
}

export class PathologyForm implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public sample?: number;
    public date?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PathologyForm>) { (Object as any).assign(this, init); }
}

export class PatientForm implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantNumber: string;
    public dateRandomised?: string;
    public randomisingInstitutionId?: number;
    public currentInstitutionId?: number;
    public status?: number;
    public hasPatientConsentedTissueBanking?: number;
    public datePatientSignedTissueBanking?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientForm>) { (Object as any).assign(this, init); }
}

export class SeriousAdverseEventForm implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ctcaeTerm: string;
    public eventOnset?: string;
    public eventCeased?: string;
    public outcome?: number;
    public relationship?: number;
    public determinationBySponsor?: number;
    public reportedToTga?: string;
    public reportedToHrec?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SeriousAdverseEventForm>) { (Object as any).assign(this, init); }
}

export class StatusForm implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: number;
    public date?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<StatusForm>) { (Object as any).assign(this, init); }
}

export class TransferForm implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateNotified?: string;
    public dateReported?: string;
    public institutionId?: number;
    public datePicIcSigned?: string;
    public dateReferred?: string;
    public dateConfirmed?: string;
    public firstFollowUp?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TransferForm>) { (Object as any).assign(this, init); }
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
    EConsentView = 40,
    EConsentAdminister = 41,
    EConsentManage = 42,
    PISignOffView = 43,
    PISignOffPerform = 44,
    PISignOffAdminister = 45,
}

export enum PatientStateType
{
    Randomised = 1,
}

export enum EventDefinitionType
{
    Randomisation = 1,
}

export enum FormDefinitionType
{
    PatientForm = 1,
    TransferForm = 2,
    StatusForm = 3,
    PathologyForm = 4,
    SeriousAdverseEventForm = 5,
}

export enum TreatmentType
{
    Default = 1,
}

export enum LookupDeterminationType
{
    Sar = 1,
    Susar = 2,
}

export enum LookupOutcomeType
{
    Recovered = 1,
    Recovering = 2,
    RecoveredWithSequelae = 3,
    DeathOrUnknown = 4,
}

export enum LookupRelationshipType
{
    RelatedToIp = 1,
    RelatedToStudyProcedure = 2,
    Unrelated = 3,
}

export enum LookupSampleType
{
    ArchivalTissue = 1,
    PkSample = 2,
    CfDna = 3,
}

export enum LookupStatusType
{
    OnTreatment = 1,
    EndOfStudyConsentWithdrawn = 3,
    EndOfStudyLostToFollowUp = 4,
    EndOfStudyDeceased = 5,
    EndOfTreatmentProgressiveDisease = 7,
    EndOfTreatmentUnacceptableToxicities = 8,
    EndOfTreatmentPtChoice = 9,
}

export enum LookupYesNoType
{
    Yes = 1,
    No = 2,
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

export class PicfType
{
    public id?: number;
    public name: string;
    public label: string;
    public fileName: string;

    public constructor(init?: Partial<PicfType>) { (Object as any).assign(this, init); }
}

export class Picf
{
    public type: PicfType;
    public picfId?: number;
    public name: string;
    public label: string;
    public version: string;
    public fileName: string;
    public file: string;

    public constructor(init?: Partial<Picf>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    TrialPersonnel = 1,
    Patient = 2,
}

export class LookupItem implements ILookupItem
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinitionState implements IEventDefinitionFormDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinitionFormDefinition)")
    public eventDefinitionFormDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionFormDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinition
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public states: EventDefinitionFormDefinitionState[];

    public constructor(init?: Partial<EventDefinitionFormDefinition>) { (Object as any).assign(this, init); }
}

export class EventDefinitionState implements IEventDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionState>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class EventDefinitionErrorMessage implements IEventDefinitionErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventDefinitionErrorMessage>) { (Object as any).assign(this, init); }
}

export class EventDefinition
{
    public id?: number;
    public name: string;
    public code: string;
    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public formDefinitions: EventDefinitionFormDefinition[];
    public states: EventDefinitionState[];
    public errorMessages: EventDefinitionErrorMessage[];

    public constructor(init?: Partial<EventDefinition>) { (Object as any).assign(this, init); }
}

export enum FormType
{
    Crf = 1,
    Pro = 2,
    Staff = 3,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class PatientState implements IPatientState
{
    public id?: number;
    public name: string;
    public order?: number;

    public constructor(init?: Partial<PatientState>) { (Object as any).assign(this, init); }
}

export class Treatment implements ITreatment
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements ICollaboratingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public collaboratingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public recruitmentDisabled?: boolean;
    public eConsentActivationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class PatientValidationError implements IPatientValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<PatientValidationError>) { (Object as any).assign(this, init); }
}

export class EventValidationError implements IEventValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export class EventValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public eventId?: number;
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: EventValidationError[];
    public formResults: FormValidationResult[];

    public constructor(init?: Partial<EventValidationResult>) { (Object as any).assign(this, init); }
}

export class PatientValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public patientId?: number;
    public patientDefinitionId?: number;
    public patientRepeat?: number;
    public errors: PatientValidationError[];
    public eventResults: EventValidationResult[];

    public constructor(init?: Partial<PatientValidationResult>) { (Object as any).assign(this, init); }
}

export interface IEoi extends IDataModel
{
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.Invitation)")
    invitationId?: number;

    firstName: string;
    lastName: string;
    confirmed?: boolean;
    dateSubmitted?: string;
    statusId?: StatusTypeEnum;
    invitation: Invitation;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IPatientConsent extends IDataModel
{
    eoiId?: number;
    typeId?: ConsentTypeEnum;
    statusId?: StatusTypeEnum;
    guid: string;
    dateStarted?: string;
    mobileNumber: string;
    patientSigned?: boolean;
    datePatientSigned?: string;
    investigatorSigned?: boolean;
    dateInvestigatorSigned?: string;
    documentVerified?: boolean;
    documentVerifiedBy: string;
    dateDocumentVerified?: string;
    dateNotificationSent?: string;
    documentUpload: ConsentFileUpload;
    type: ConsentType;
    status: StatusType;
    eoi: IEoi;
    picfs: ConsentPicf[];
}

export interface IPatient extends IDataModel, IHasInstitutionId, IHasPatientStateId, IHasTreatmentId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientStateId
{
    patientStateId?: number;
}

export interface IHasTreatmentId
{
    treatmentId?: number;
}

export interface IEvent extends IDataModel, IHasPatientId, IHasEventDefinitionId
{
    repeat?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IHasEventDefinitionId
{
    eventDefinitionId?: number;
}

export interface IForm extends IDataModel, IHasPatientId, IHasEventId, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IHasEventId
{
    eventId?: number;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface ILookupItem
{
    id?: number;
    value: string;
    order?: number;
    type?: number;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IEventDefinitionFormDefinitionState extends IConfigurationModel, IHasEventDefinitionFormDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionFormDefinition extends IConfigurationModel, IHasEventDefinitionId, IHasFormDefinitionId
{
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    states: IEventDefinitionFormDefinitionState[];
}

export interface IEventDefinitionState extends IConfigurationModel, IHasEventDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionErrorMessage extends IConfigurationModel, IHasEventDefinitionId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IEventDefinition extends IConfigurationModel
{
    name: string;
    code: string;
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    formDefinitions: IEventDefinitionFormDefinition[];
    states: IEventDefinitionState[];
    errorMessages: IEventDefinitionErrorMessage[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    patientInstructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IPatientState extends IConfigurationModel
{
    name: string;
    order?: number;
}

export interface ITreatment extends IConfigurationModel
{
    name: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICollaboratingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCollaboratingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
    recruitmentDisabled?: boolean;
    eConsentActivationDate?: string;
}

export interface IHasCollaboratingGroupId
{
    collaboratingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatientValidationError extends IValidationError
{
}

export interface IEventValidationError extends IValidationError
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IFormValidationError[];
}

export interface IEventValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    eventDefinitionId?: number;
    eventId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IEventValidationError[];
    formResults: IFormValidationResult[];
}

export interface IPatientValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    patientId?: number;
    errors: IPatientValidationError[];
    eventResults: IEventValidationResult[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasEventDefinitionFormDefinitionId
{
    eventDefinitionFormDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class GetEnumerationTypesResponse
{
    public patientStateType?: PatientStateType;
    public eventDefinitionType?: EventDefinitionType;
    public formDefinitionType?: FormDefinitionType;
    public treatmentType?: TreatmentType;
    public lookupDeterminationType?: LookupDeterminationType;
    public lookupOutcomeType?: LookupOutcomeType;
    public lookupRelationshipType?: LookupRelationshipType;
    public lookupSampleType?: LookupSampleType;
    public lookupStatusType?: LookupStatusType;
    public lookupYesNoType?: LookupYesNoType;

    public constructor(init?: Partial<GetEnumerationTypesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class GetEConsentSettingsResponse
{
    public eConsentActivated: boolean;
    public eConsentActivatedInstitutions: number[];
    public trialInfoPageUrl: string;
    public trialConsentPageUrl: string;
    public trialConsentSignedPageUrl: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetEConsentSettingsResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentInvitationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentInvitationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentEoiEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentEoiEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentPatientEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentPatientEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentSuccessEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentSuccessEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentSuccessTrialEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentSuccessTrialEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentRefusedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentRefusedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentRefusedPatientEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentRefusedPatientEmailResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetCollectionResponse
{
    public responseStatus: ResponseStatus;
    public invitations: Invitation[];

    public constructor(init?: Partial<InvitationGetCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByIdResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByIdResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByGuidResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByGuidResponse>) { (Object as any).assign(this, init); }
}

export class InvitationSaveResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSaveResponse>) { (Object as any).assign(this, init); }
}

export class InvitationDeleteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<InvitationDeleteResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetInvestigatorsResponse
{
    public responseStatus: ResponseStatus;
    public investigators: { [index: string]: string; };

    public constructor(init?: Partial<InvitationGetInvestigatorsResponse>) { (Object as any).assign(this, init); }
}

export class GetPicfsResponse
{
    public responseStatus: ResponseStatus;
    public picfs: Picf[];

    public constructor(init?: Partial<GetPicfsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eoi.
    */
    // @DataMember(Order=2)
    public eoi: Eoi;

    public constructor(init?: Partial<EoiSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eois.
    */
    // @DataMember(Order=2)
    public eois: Eoi[];

    public constructor(init?: Partial<EoiCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EoiDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patientconsent.
    */
    // @DataMember(Order=2)
    public patientConsent: PatientConsent;

    public constructor(init?: Partial<PatientConsentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patientconsents.
    */
    // @DataMember(Order=2)
    public patientConsents: PatientConsent[];

    public constructor(init?: Partial<PatientConsentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientConsentDeleteResponse>) { (Object as any).assign(this, init); }
}

export class PatientConsentSendVerificationCodeResponse
{
    public responseStatus: ResponseStatus;
    public verificationCode: number;

    public constructor(init?: Partial<PatientConsentSendVerificationCodeResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definition.
    */
    // @DataMember(Order=2)
    public eventDefinition: EventDefinition;

    public constructor(init?: Partial<EventDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definitions.
    */
    // @DataMember(Order=2)
    public eventDefinitions: EventDefinition[];

    public constructor(init?: Partial<EventDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient state.
    */
    // @DataMember(Order=2)
    public patientState: PatientState;

    public constructor(init?: Partial<PatientStateSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient states.
    */
    // @DataMember(Order=2)
    public patientStates: PatientState[];

    public constructor(init?: Partial<PatientStateCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatment.
    */
    // @DataMember(Order=2)
    public treatment: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatments.
    */
    // @DataMember(Order=2)
    public treatments: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public masterGroup: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public masterGroups: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating group.
    */
    // @DataMember(Order=2)
    public collaboratingGroup: CollaboratingGroup;

    public constructor(init?: Partial<CollaboratingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating groups.
    */
    // @DataMember(Order=2)
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<CollaboratingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public country: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public countries: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public institution: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public institutions: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public patient: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: PatientValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public patients: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event.
    */
    // @DataMember(Order=2)
    public event: Event;

    public constructor(init?: Partial<EventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved events.
    */
    // @DataMember(Order=2)
    public events: Event[];

    public constructor(init?: Partial<EventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pathology form.
    */
    // @DataMember(Order=2)
    public form: PathologyForm;

    public constructor(init?: Partial<PathologyFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pathology forms.
    */
    // @DataMember(Order=2)
    public forms: PathologyForm[];

    public constructor(init?: Partial<PathologyFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PathologyFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PathologyFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient form.
    */
    // @DataMember(Order=2)
    public form: PatientForm;

    public constructor(init?: Partial<PatientFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient forms.
    */
    // @DataMember(Order=2)
    public forms: PatientForm[];

    public constructor(init?: Partial<PatientFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PatientFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse event form.
    */
    // @DataMember(Order=2)
    public form: SeriousAdverseEventForm;

    public constructor(init?: Partial<SeriousAdverseEventFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse event forms.
    */
    // @DataMember(Order=2)
    public forms: SeriousAdverseEventForm[];

    public constructor(init?: Partial<SeriousAdverseEventFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SeriousAdverseEventFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SeriousAdverseEventFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class StatusFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved status form.
    */
    // @DataMember(Order=2)
    public form: StatusForm;

    public constructor(init?: Partial<StatusFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class StatusFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved status forms.
    */
    // @DataMember(Order=2)
    public forms: StatusForm[];

    public constructor(init?: Partial<StatusFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class StatusFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StatusFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class StatusFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<StatusFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TransferFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved transfer form.
    */
    // @DataMember(Order=2)
    public form: TransferForm;

    public constructor(init?: Partial<TransferFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TransferFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved transfer forms.
    */
    // @DataMember(Order=2)
    public forms: TransferForm[];

    public constructor(init?: Partial<TransferFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TransferFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TransferFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TransferFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TransferFormValidationResponse>) { (Object as any).assign(this, init); }
}

export class HasOpmsPermissionCollectionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: { [index: number]: boolean; };

    public constructor(init?: Partial<HasOpmsPermissionCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<InstitutionPersonnelNamesResponse>) { (Object as any).assign(this, init); }
}

export class TrialPersonnelActiveRoleResponse
{
    public responseStatus: ResponseStatus;
    public hasRole: { [index: number]: boolean; };

    public constructor(init?: Partial<TrialPersonnelActiveRoleResponse>) { (Object as any).assign(this, init); }
}

export class GetEnumerationTypes implements IReturn<GetEnumerationTypesResponse>
{

    public constructor(init?: Partial<GetEnumerationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnumerationTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEnumerationTypesResponse(); }
}

// @Route("/opms/econsent/settings/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetEConsentSettings implements IReturn<GetEConsentSettingsResponse>
{

    public constructor(init?: Partial<GetEConsentSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEConsentSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetEConsentSettingsResponse(); }
}

export class SendEConsentInvitationEmail implements IReturn<SendEConsentInvitationEmailResponse>
{
    public invitationId: number;

    public constructor(init?: Partial<SendEConsentInvitationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentInvitationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentInvitationEmailResponse(); }
}

export class SendEConsentEoiEmail implements IReturn<SendEConsentEoiEmailResponse>
{
    public eoiId: number;

    public constructor(init?: Partial<SendEConsentEoiEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentEoiEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentEoiEmailResponse(); }
}

export class SendEConsentPatientEmail implements IReturn<SendEConsentPatientEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentPatientEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentPatientEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentPatientEmailResponse(); }
}

export class SendEConsentInvestigatorEmail implements IReturn<SendEConsentInvestigatorEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentInvestigatorEmailResponse(); }
}

export class SendEConsentSuccessEmail implements IReturn<SendEConsentSuccessEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentSuccessEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentSuccessEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentSuccessEmailResponse(); }
}

export class SendEConsentSuccessTrialEmail implements IReturn<SendEConsentSuccessTrialEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentSuccessTrialEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentSuccessTrialEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentSuccessTrialEmailResponse(); }
}

export class SendEConsentRefusedEmail implements IReturn<SendEConsentRefusedEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentRefusedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentRefusedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentRefusedEmailResponse(); }
}

export class SendEConsentRefusedPatientEmail implements IReturn<SendEConsentRefusedPatientEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentRefusedPatientEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentRefusedPatientEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentRefusedPatientEmailResponse(); }
}

// @Route("/identity-server/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/identity-server/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/opms/invitation/collection", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetCollection implements IReturn<InvitationGetCollectionResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetCollectionResponse(); }
}

// @Route("/opms/invitation/single/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleById implements IReturn<InvitationGetSingleByIdResponse>, IGet
{
    public id?: number;

    public constructor(init?: Partial<InvitationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByIdResponse(); }
}

// @Route("/opms/invitation/single/{Guid}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleByGuid implements IReturn<InvitationGetSingleByGuidResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<InvitationGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByGuidResponse(); }
}

// @Route("/opms/invitation/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationSave implements IReturn<InvitationSaveResponse>, IPost
{
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvitationSaveResponse(); }
}

// @Route("/opms/invitation/delete/{Id}", "DELETE")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationDelete implements IReturn<InvitationDeleteResponse>, IDelete
{
    public id?: number;

    public constructor(init?: Partial<InvitationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new InvitationDeleteResponse(); }
}

// @Route("/opms/invitation/investigators", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetInvestigators implements IReturn<InvitationGetInvestigatorsResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetInvestigators>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetInvestigators'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetInvestigatorsResponse(); }
}

// @Route("/opms/picf/{InstCode}/{PatientConsentId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetPicfs implements IReturn<GetPicfsResponse>, IGet
{
    public instCode: string;
    public patientConsentId?: number;

    public constructor(init?: Partial<GetPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPicfsResponse(); }
}

// @Route("/opms/picf/download/all/{InstCode}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicfs implements IReturn<Blob>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetDownloadPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/picf/download/{InstCode}/{PicfId}/{PicfTypeId}/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicf implements IReturn<Blob>, IGet
{
    public instCode: string;
    public picfId?: number;
    public picfTypeId?: number;

    public constructor(init?: Partial<GetDownloadPicf>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicf'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/single/id/{Id}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiGetSingleById implements IReturn<EoiSingleResponse>, IEoiGetSingleById
{
    /**
    * The ID of the eoi to retrieve.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiSingleResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/collection", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollection implements IReturn<EoiCollectionResponse>, IEoiGetCollection
{

    public constructor(init?: Partial<EoiGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollectionByInstitutionCode implements IReturn<EoiCollectionResponse>, IEoiGetCollectionByInstitutionCode
{
    /**
    * The institution code of the eoi to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<EoiGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/save", "POST")
// @Api(Description="Eoi")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiPostSave implements IReturn<EoiSingleResponse>
{
    /**
    * The eoi to save.
    */
    // @DataMember(Order=1)
    public eoi: Eoi;

    /**
    * The eoi to save.
    */
    // @DataMember(Order=2)
    public invitationGuid: string;

    public constructor(init?: Partial<EoiPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EoiSingleResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/delete/{Id}", "DELETE")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=500)
// @DataContract
export class EoiDelete implements IReturn<EoiDeleteResponse>, IEoiDelete
{
    /**
    * The eoi ID to delete.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EoiDeleteResponse(); }
}

// @Route("/opms/eoi/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class EoiSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<EoiSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/single/id/{Id}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleById implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleById
{
    /**
    * The ID of the patientconsent to retrieve.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/single/guid/{Guid}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleByGuid implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleByGuid
{
    /**
    * The guid of the patientconsent to retrieve.
    */
    // @DataMember(Order=1)
    public guid: string;

    public constructor(init?: Partial<PatientConsentGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/collection", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollection implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollection
{

    public constructor(init?: Partial<PatientConsentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollectionByInstitutionCode implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollectionByInstitutionCode
{
    /**
    * The institution code of the patientconsent to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<PatientConsentGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/save", "POST")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentPostSave implements IReturn<PatientConsentSingleResponse>
{
    /**
    * The patientconsent to save.
    */
    // @DataMember(Order=1)
    public patientConsent: PatientConsent;

    /**
    * The patientconsent to check.
    */
    // @DataMember(Order=1)
    public emailRequired: boolean;

    public constructor(init?: Partial<PatientConsentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/delete/{Id}", "DELETE")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientConsentDelete implements IReturn<PatientConsentDeleteResponse>, IPatientConsentDelete
{
    /**
    * The patientconsent ID to delete.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientConsentDeleteResponse(); }
}

// @Route("/opms/patient-consent/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<PatientConsentSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-reminder", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendReminder implements IReturn<ResponseStatus>, IPost
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentSendReminder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendReminder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-verification-code", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendVerificationCode implements IReturn<PatientConsentSendVerificationCodeResponse>, IPost
{
    public phoneNumber: string;

    public constructor(init?: Partial<PatientConsentSendVerificationCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendVerificationCode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSendVerificationCodeResponse(); }
}

// @Route("/opms/patient-consent/view-consent-file/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentViewConsentFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentViewConsentFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentViewConsentFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* Sign In
*/
// @Route("/auth")
// @Route("/auth/{provider}")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="AuthProvider, e.g. credentials")
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/opms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserResponse(); }
}

/**
* Lookup
*/
// @Route("/opms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/opms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/id/{Id}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleById implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleById
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/code/{Code}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleByCode implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleByCode
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<EventDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/collection", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetCollection implements IReturn<EventDefinitionCollectionResponse>, IEventDefinitionGetCollection
{

    public constructor(init?: Partial<EventDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionCollectionResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/single/id/{Id}", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetSingleById implements IReturn<PatientStateSingleResponse>, IPatientStateGetSingleById
{
    /**
    * The ID of the patient state to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStateGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateSingleResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/collection", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetCollection implements IReturn<PatientStateCollectionResponse>, IPatientStateGetCollection
{

    public constructor(init?: Partial<PatientStateGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateCollectionResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/single/id/{Id}", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, ITreatmentGetSingleById
{
    /**
    * The ID of the treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/collection", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, ITreatmentGetCollection
{

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/code/{Code}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleByCode implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleByCode
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MasterGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IMasterGroupGetCollection
{

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/id/{Id}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleById implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleById
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CollaboratingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/code/{Code}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleByCode implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleByCode
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CollaboratingGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollection implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollection
{

    public constructor(init?: Partial<CollaboratingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupId implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupCode implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, ICountryGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/code/{Code}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleByCode implements IReturn<CountrySingleResponse>, ICountryGetSingleByCode
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CountryGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, ICountryGetCollection
{

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/code/{Code}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleByCode implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleByCode
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<InstitutionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollection
{

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/id/{CountryId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IPatientGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationById implements IReturn<PatientValidationResponse>, IPatientGetValidationById
{
    /**
    * The ID of the patient to validate.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetValidationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationByStudyNumber implements IReturn<PatientValidationResponse>, IPatientGetValidationByStudyNumber
{
    /**
    * The Study Number of the patient to validate.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetValidationByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IPatientGetCollection
{
    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/id/{CountryId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/code/{InstitutionCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionCode
{
    /**
    * The Code of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/Save", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/ids", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithIds implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    public constructor(init?: Partial<PatientPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/codes", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithCodes implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    public constructor(init?: Partial<PatientPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/id/{Id}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleById implements IReturn<EventSingleResponse>, IEventGetSingleById
{
    /**
    * The ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/ids", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByIds implements IReturn<EventSingleResponse>, IEventGetSingleByIds
{
    /**
    * The Patient ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Id of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/codes", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByCodes implements IReturn<EventSingleResponse>, IEventGetSingleByCodes
{
    /**
    * The Patient Study Number of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Code of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollection implements IReturn<EventCollectionResponse>, IEventGetCollection
{

    public constructor(init?: Partial<EventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/id/{PatientId}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientId implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientId
{
    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId: number;

    public constructor(init?: Partial<EventGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/study-number/{PatientStudyNumber}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientStudyNumber implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientStudyNumber
{
    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    public constructor(init?: Partial<EventGetCollectionByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/Save", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSave implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    public constructor(init?: Partial<EventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/ids", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithIds implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The ID of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/codes", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithCodes implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The Code of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/single/id/{Id}", "GET")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyFormGetSingleById implements IReturn<PathologyFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PathologyFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyFormSingleResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/single/ids", "GET")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyFormGetSingleByIds implements IReturn<PathologyFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the pathology form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pathology form if no pathology form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PathologyFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyFormSingleResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/single/codes", "GET")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyFormGetSingleByCodes implements IReturn<PathologyFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the pathology form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pathology form if no pathology form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PathologyFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyFormSingleResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/collection", "GET")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PathologyFormGetCollection implements IReturn<PathologyFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PathologyFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyFormCollectionResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PathologyFormGetCollectionByIds implements IReturn<PathologyFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyFormCollectionResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PathologyFormGetCollectionByCodes implements IReturn<PathologyFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyFormCollectionResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/save", "POST")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyFormPostSave implements IReturn<PathologyFormSingleResponse>
{
    /**
    * The pathology form to save.
    */
    // @DataMember(Order=1)
    public form: PathologyForm;

    public constructor(init?: Partial<PathologyFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyFormSingleResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/save/ids", "POST")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyFormPostSaveWithIds implements IReturn<PathologyFormSingleResponse>
{
    /**
    * The pathology form to save.
    */
    // @DataMember(Order=1)
    public form: PathologyForm;

    /**
    * The ID of the Institution associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pathology form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PathologyFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyFormSingleResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/save/codes", "POST")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyFormPostSaveWithCodes implements IReturn<PathologyFormSingleResponse>
{
    /**
    * The pathology form to save.
    */
    // @DataMember(Order=1)
    public form: PathologyForm;

    /**
    * The Code of the Institution associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pathology form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PathologyFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyFormSingleResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/delete", "DELETE")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyFormDelete implements IReturn<PathologyFormDeleteResponse>
{
    /**
    * The pathology form to delete.
    */
    // @DataMember(Order=1)
    public form: PathologyForm;

    public constructor(init?: Partial<PathologyFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyFormDeleteResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyFormDeleteWithId implements IReturn<PathologyFormDeleteResponse>
{
    /**
    * The ID of the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PathologyFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyFormDeleteResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/delete/ids", "DELETE")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyFormDeleteWithIds implements IReturn<PathologyFormDeleteResponse>
{
    /**
    * The Patient ID of the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PathologyFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyFormDeleteResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/delete/codes", "DELETE")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyFormDeleteByCodes implements IReturn<PathologyFormDeleteResponse>
{
    /**
    * The Patient Study Number of the pathology form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PathologyFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyFormDeleteResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/validate", "POST")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=500)
// @DataContract
export class PathologyFormPostValidate implements IReturn<PathologyFormValidationResponse>
{
    /**
    * The pathology form to validate.
    */
    // @DataMember(Order=1)
    public form: PathologyForm;

    public constructor(init?: Partial<PathologyFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyFormValidationResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/validate/ids", "POST")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=500)
// @DataContract
export class PathologyFormPostValidateWithIds implements IReturn<PathologyFormValidationResponse>
{
    /**
    * The pathology form to validate.
    */
    // @DataMember(Order=1)
    public form: PathologyForm;

    /**
    * The ID of the Institution associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyFormValidationResponse(); }
}

/**
* Form - Pathology Form
*/
// @Route("/opms/form/pathology-form/validate/codes", "POST")
// @Api(Description="Form - Pathology Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyFormValidationResponse)", StatusCode=500)
// @DataContract
export class PathologyFormPostValidateWithCodes implements IReturn<PathologyFormValidationResponse>
{
    /**
    * The pathology form to validate.
    */
    // @DataMember(Order=1)
    public form: PathologyForm;

    /**
    * The Code of the Institution associated with the pathology form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pathology form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pathology form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pathology form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyFormValidationResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/single/id/{Id}", "GET")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientFormGetSingleById implements IReturn<PatientFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientFormSingleResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/single/ids", "GET")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientFormGetSingleByIds implements IReturn<PatientFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the patient form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new patient form if no patient form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientFormSingleResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/single/codes", "GET")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientFormGetSingleByCodes implements IReturn<PatientFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the patient form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new patient form if no patient form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientFormSingleResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/collection", "GET")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientFormGetCollection implements IReturn<PatientFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PatientFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientFormCollectionResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientFormGetCollectionByIds implements IReturn<PatientFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientFormCollectionResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientFormGetCollectionByCodes implements IReturn<PatientFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientFormCollectionResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/save", "POST")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientFormPostSave implements IReturn<PatientFormSingleResponse>
{
    /**
    * The patient form to save.
    */
    // @DataMember(Order=1)
    public form: PatientForm;

    public constructor(init?: Partial<PatientFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientFormSingleResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/save/ids", "POST")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientFormPostSaveWithIds implements IReturn<PatientFormSingleResponse>
{
    /**
    * The patient form to save.
    */
    // @DataMember(Order=1)
    public form: PatientForm;

    /**
    * The ID of the Institution associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the patient form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientFormSingleResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/save/codes", "POST")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientFormPostSaveWithCodes implements IReturn<PatientFormSingleResponse>
{
    /**
    * The patient form to save.
    */
    // @DataMember(Order=1)
    public form: PatientForm;

    /**
    * The Code of the Institution associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the patient form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientFormSingleResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/delete", "DELETE")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientFormDelete implements IReturn<PatientFormDeleteResponse>
{
    /**
    * The patient form to delete.
    */
    // @DataMember(Order=1)
    public form: PatientForm;

    public constructor(init?: Partial<PatientFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientFormDeleteResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientFormDeleteWithId implements IReturn<PatientFormDeleteResponse>
{
    /**
    * The ID of the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientFormDeleteResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/delete/ids", "DELETE")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientFormDeleteWithIds implements IReturn<PatientFormDeleteResponse>
{
    /**
    * The Patient ID of the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientFormDeleteResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/delete/codes", "DELETE")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientFormDeleteByCodes implements IReturn<PatientFormDeleteResponse>
{
    /**
    * The Patient Study Number of the patient form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientFormDeleteResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/validate", "POST")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=500)
// @DataContract
export class PatientFormPostValidate implements IReturn<PatientFormValidationResponse>
{
    /**
    * The patient form to validate.
    */
    // @DataMember(Order=1)
    public form: PatientForm;

    public constructor(init?: Partial<PatientFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientFormValidationResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/validate/ids", "POST")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=500)
// @DataContract
export class PatientFormPostValidateWithIds implements IReturn<PatientFormValidationResponse>
{
    /**
    * The patient form to validate.
    */
    // @DataMember(Order=1)
    public form: PatientForm;

    /**
    * The ID of the Institution associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientFormValidationResponse(); }
}

/**
* Form - Patient Form
*/
// @Route("/opms/form/patient-form/validate/codes", "POST")
// @Api(Description="Form - Patient Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientFormValidationResponse)", StatusCode=500)
// @DataContract
export class PatientFormPostValidateWithCodes implements IReturn<PatientFormValidationResponse>
{
    /**
    * The patient form to validate.
    */
    // @DataMember(Order=1)
    public form: PatientForm;

    /**
    * The Code of the Institution associated with the patient form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the patient form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the patient form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientFormValidationResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/single/id/{Id}", "GET")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormGetSingleById implements IReturn<SeriousAdverseEventFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventFormSingleResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/single/ids", "GET")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormGetSingleByIds implements IReturn<SeriousAdverseEventFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the serious adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new serious adverse event form if no serious adverse event form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SeriousAdverseEventFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventFormSingleResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/single/codes", "GET")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormGetSingleByCodes implements IReturn<SeriousAdverseEventFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the serious adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new serious adverse event form if no serious adverse event form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SeriousAdverseEventFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventFormSingleResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/collection", "GET")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormGetCollection implements IReturn<SeriousAdverseEventFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SeriousAdverseEventFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventFormCollectionResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormGetCollectionByIds implements IReturn<SeriousAdverseEventFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventFormCollectionResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormGetCollectionByCodes implements IReturn<SeriousAdverseEventFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventFormCollectionResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/save", "POST")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormPostSave implements IReturn<SeriousAdverseEventFormSingleResponse>
{
    /**
    * The serious adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: SeriousAdverseEventForm;

    public constructor(init?: Partial<SeriousAdverseEventFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventFormSingleResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/save/ids", "POST")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormPostSaveWithIds implements IReturn<SeriousAdverseEventFormSingleResponse>
{
    /**
    * The serious adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: SeriousAdverseEventForm;

    /**
    * The ID of the Institution associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SeriousAdverseEventFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventFormSingleResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/save/codes", "POST")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormPostSaveWithCodes implements IReturn<SeriousAdverseEventFormSingleResponse>
{
    /**
    * The serious adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: SeriousAdverseEventForm;

    /**
    * The Code of the Institution associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SeriousAdverseEventFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventFormSingleResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/delete", "DELETE")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormDelete implements IReturn<SeriousAdverseEventFormDeleteResponse>
{
    /**
    * The serious adverse event form to delete.
    */
    // @DataMember(Order=1)
    public form: SeriousAdverseEventForm;

    public constructor(init?: Partial<SeriousAdverseEventFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SeriousAdverseEventFormDeleteResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormDeleteWithId implements IReturn<SeriousAdverseEventFormDeleteResponse>
{
    /**
    * The ID of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SeriousAdverseEventFormDeleteResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/delete/ids", "DELETE")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormDeleteWithIds implements IReturn<SeriousAdverseEventFormDeleteResponse>
{
    /**
    * The Patient ID of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SeriousAdverseEventFormDeleteResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/delete/codes", "DELETE")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormDeleteByCodes implements IReturn<SeriousAdverseEventFormDeleteResponse>
{
    /**
    * The Patient Study Number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SeriousAdverseEventFormDeleteResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/validate", "POST")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormPostValidate implements IReturn<SeriousAdverseEventFormValidationResponse>
{
    /**
    * The serious adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: SeriousAdverseEventForm;

    public constructor(init?: Partial<SeriousAdverseEventFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventFormValidationResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/validate/ids", "POST")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormPostValidateWithIds implements IReturn<SeriousAdverseEventFormValidationResponse>
{
    /**
    * The serious adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: SeriousAdverseEventForm;

    /**
    * The ID of the Institution associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventFormValidationResponse(); }
}

/**
* Form - Serious Adverse Event Form
*/
// @Route("/opms/form/serious-adverse-event-form/validate/codes", "POST")
// @Api(Description="Form - Serious Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SeriousAdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventFormPostValidateWithCodes implements IReturn<SeriousAdverseEventFormValidationResponse>
{
    /**
    * The serious adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: SeriousAdverseEventForm;

    /**
    * The Code of the Institution associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the serious adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventFormValidationResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/single/id/{Id}", "GET")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class StatusFormGetSingleById implements IReturn<StatusFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the status form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<StatusFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusFormSingleResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/single/ids", "GET")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class StatusFormGetSingleByIds implements IReturn<StatusFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the status form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the status form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the status form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new status form if no status form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<StatusFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusFormSingleResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/single/codes", "GET")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class StatusFormGetSingleByCodes implements IReturn<StatusFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the status form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the status form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the status form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new status form if no status form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<StatusFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusFormSingleResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/collection", "GET")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=500)
// @DataContract
export class StatusFormGetCollection implements IReturn<StatusFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<StatusFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusFormCollectionResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=500)
// @DataContract
export class StatusFormGetCollectionByIds implements IReturn<StatusFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the status form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<StatusFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusFormCollectionResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormCollectionResponse)", StatusCode=500)
// @DataContract
export class StatusFormGetCollectionByCodes implements IReturn<StatusFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the status form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<StatusFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusFormCollectionResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/save", "POST")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class StatusFormPostSave implements IReturn<StatusFormSingleResponse>
{
    /**
    * The status form to save.
    */
    // @DataMember(Order=1)
    public form: StatusForm;

    public constructor(init?: Partial<StatusFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StatusFormSingleResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/save/ids", "POST")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class StatusFormPostSaveWithIds implements IReturn<StatusFormSingleResponse>
{
    /**
    * The status form to save.
    */
    // @DataMember(Order=1)
    public form: StatusForm;

    /**
    * The ID of the Institution associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the status form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<StatusFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StatusFormSingleResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/save/codes", "POST")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class StatusFormPostSaveWithCodes implements IReturn<StatusFormSingleResponse>
{
    /**
    * The status form to save.
    */
    // @DataMember(Order=1)
    public form: StatusForm;

    /**
    * The Code of the Institution associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the status form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the status form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<StatusFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StatusFormSingleResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/delete", "DELETE")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class StatusFormDelete implements IReturn<StatusFormDeleteResponse>
{
    /**
    * The status form to delete.
    */
    // @DataMember(Order=1)
    public form: StatusForm;

    public constructor(init?: Partial<StatusFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new StatusFormDeleteResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class StatusFormDeleteWithId implements IReturn<StatusFormDeleteResponse>
{
    /**
    * The ID of the status form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<StatusFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new StatusFormDeleteResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/delete/ids", "DELETE")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class StatusFormDeleteWithIds implements IReturn<StatusFormDeleteResponse>
{
    /**
    * The Patient ID of the status form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the status form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<StatusFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new StatusFormDeleteResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/delete/codes", "DELETE")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class StatusFormDeleteByCodes implements IReturn<StatusFormDeleteResponse>
{
    /**
    * The Patient Study Number of the status form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the status form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<StatusFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new StatusFormDeleteResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/validate", "POST")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=500)
// @DataContract
export class StatusFormPostValidate implements IReturn<StatusFormValidationResponse>
{
    /**
    * The status form to validate.
    */
    // @DataMember(Order=1)
    public form: StatusForm;

    public constructor(init?: Partial<StatusFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StatusFormValidationResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/validate/ids", "POST")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=500)
// @DataContract
export class StatusFormPostValidateWithIds implements IReturn<StatusFormValidationResponse>
{
    /**
    * The status form to validate.
    */
    // @DataMember(Order=1)
    public form: StatusForm;

    /**
    * The ID of the Institution associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<StatusFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StatusFormValidationResponse(); }
}

/**
* Form - Status Form
*/
// @Route("/opms/form/status-form/validate/codes", "POST")
// @Api(Description="Form - Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.StatusFormValidationResponse)", StatusCode=500)
// @DataContract
export class StatusFormPostValidateWithCodes implements IReturn<StatusFormValidationResponse>
{
    /**
    * The status form to validate.
    */
    // @DataMember(Order=1)
    public form: StatusForm;

    /**
    * The Code of the Institution associated with the status form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the status form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the status form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the status form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<StatusFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StatusFormValidationResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/single/id/{Id}", "GET")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=500)
// @DataContract
export class TransferFormGetSingleById implements IReturn<TransferFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TransferFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TransferFormSingleResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/single/ids", "GET")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=500)
// @DataContract
export class TransferFormGetSingleByIds implements IReturn<TransferFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the transfer form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new transfer form if no transfer form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TransferFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TransferFormSingleResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/single/codes", "GET")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=500)
// @DataContract
export class TransferFormGetSingleByCodes implements IReturn<TransferFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the transfer form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new transfer form if no transfer form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TransferFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TransferFormSingleResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/collection", "GET")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=500)
// @DataContract
export class TransferFormGetCollection implements IReturn<TransferFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TransferFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TransferFormCollectionResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=500)
// @DataContract
export class TransferFormGetCollectionByIds implements IReturn<TransferFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TransferFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TransferFormCollectionResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormCollectionResponse)", StatusCode=500)
// @DataContract
export class TransferFormGetCollectionByCodes implements IReturn<TransferFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TransferFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TransferFormCollectionResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/save", "POST")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=500)
// @DataContract
export class TransferFormPostSave implements IReturn<TransferFormSingleResponse>
{
    /**
    * The transfer form to save.
    */
    // @DataMember(Order=1)
    public form: TransferForm;

    public constructor(init?: Partial<TransferFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TransferFormSingleResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/save/ids", "POST")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=500)
// @DataContract
export class TransferFormPostSaveWithIds implements IReturn<TransferFormSingleResponse>
{
    /**
    * The transfer form to save.
    */
    // @DataMember(Order=1)
    public form: TransferForm;

    /**
    * The ID of the Institution associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the transfer form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TransferFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TransferFormSingleResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/save/codes", "POST")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormSingleResponse)", StatusCode=500)
// @DataContract
export class TransferFormPostSaveWithCodes implements IReturn<TransferFormSingleResponse>
{
    /**
    * The transfer form to save.
    */
    // @DataMember(Order=1)
    public form: TransferForm;

    /**
    * The Code of the Institution associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the transfer form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TransferFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TransferFormSingleResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/delete", "DELETE")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=500)
// @DataContract
export class TransferFormDelete implements IReturn<TransferFormDeleteResponse>
{
    /**
    * The transfer form to delete.
    */
    // @DataMember(Order=1)
    public form: TransferForm;

    public constructor(init?: Partial<TransferFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TransferFormDeleteResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=500)
// @DataContract
export class TransferFormDeleteWithId implements IReturn<TransferFormDeleteResponse>
{
    /**
    * The ID of the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TransferFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TransferFormDeleteResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/delete/ids", "DELETE")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=500)
// @DataContract
export class TransferFormDeleteWithIds implements IReturn<TransferFormDeleteResponse>
{
    /**
    * The Patient ID of the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TransferFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TransferFormDeleteResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/delete/codes", "DELETE")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormDeleteResponse)", StatusCode=500)
// @DataContract
export class TransferFormDeleteByCodes implements IReturn<TransferFormDeleteResponse>
{
    /**
    * The Patient Study Number of the transfer form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TransferFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TransferFormDeleteResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/validate", "POST")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=500)
// @DataContract
export class TransferFormPostValidate implements IReturn<TransferFormValidationResponse>
{
    /**
    * The transfer form to validate.
    */
    // @DataMember(Order=1)
    public form: TransferForm;

    public constructor(init?: Partial<TransferFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TransferFormValidationResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/validate/ids", "POST")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=500)
// @DataContract
export class TransferFormPostValidateWithIds implements IReturn<TransferFormValidationResponse>
{
    /**
    * The transfer form to validate.
    */
    // @DataMember(Order=1)
    public form: TransferForm;

    /**
    * The ID of the Institution associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TransferFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TransferFormValidationResponse(); }
}

/**
* Form - Transfer Form
*/
// @Route("/opms/form/transfer-form/validate/codes", "POST")
// @Api(Description="Form - Transfer Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TransferFormValidationResponse)", StatusCode=500)
// @DataContract
export class TransferFormPostValidateWithCodes implements IReturn<TransferFormValidationResponse>
{
    /**
    * The transfer form to validate.
    */
    // @DataMember(Order=1)
    public form: TransferForm;

    /**
    * The Code of the Institution associated with the transfer form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the transfer form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the transfer form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the transfer form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TransferFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TransferFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TransferFormValidationResponse(); }
}

// @Route("/opms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download-all/{PatientId}/{FormDefinitionId}/{FormId}", "GET")
export class DownloadAllFiles implements IReturn<Blob>, IGet
{
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;

    public constructor(init?: Partial<DownloadAllFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadAllFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

export class PostHasOpmsPermissionsByIds implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionId?: number;
    public patientId?: number;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class PostHasOpmsPermissionsByCodes implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupCode: string;
    public coordinatingGroupCode: string;
    public institutionCode: string;
    public patientStudyNumber: string;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class GetInstitutionPersonnelNames implements IReturn<InstitutionPersonnelNamesResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnelNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnelNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelNamesResponse(); }
}

export class GetTrialPersonnelHasActiveRole implements IReturn<TrialPersonnelActiveRoleResponse>, IGet
{
    public personId: number;
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetTrialPersonnelHasActiveRole>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTrialPersonnelHasActiveRole'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrialPersonnelActiveRoleResponse(); }
}

